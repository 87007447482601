import { useInView } from "react-intersection-observer";
import { HomePageContext } from "/context/HomePageContext";
import { useContext } from "react";
import DataHandler from "../../Skeleton";
import ExploreMoreSection from "../ExploreMoreSection";

const ExploreMore = ({ sendBannerClickGAEvent, styles }) => {
    const [{ isMobileType, cms_data }] = useContext(HomePageContext);
    const { ref: exploreMoreRef, inView: exploreMoreView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div id="banner_9" className={styles.home_mobile_sec5} ref={exploreMoreRef}
            style={{ minHeight: isMobileType ? '46rem' : '35rem' }}
            onClick={() => {
                sendBannerClickGAEvent({
                    bannerItem: {
                        id: 9,
                        title: cms_data.homepage_banner_promotion9_title,
                        text: cms_data.homepage_banner_promotion9_text
                    },
                })
            }}
        >
            <h2>{cms_data.homepage_banner_promotion9_title}</h2>
            <DataHandler condition={exploreMoreView} height={isMobileType ? 740 : 560}>
                <ExploreMoreSection />
            </DataHandler>
        </div>
    )
}

export default ExploreMore;