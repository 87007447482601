import { useContext } from "react";
import Link from "next/link";
import { Carousel as PCarousel } from "react-responsive-carousel";
import styles from '../../Home.module.css'
import { HomePageContext } from "/context/HomePageContext";
import { cloudflareLoader } from "/lib/services/helpers/cloudflareLoader";

const HomepageBanner = ({ sendBannerClickGAEvent, sendHeroBannerImpressionGAEvent, banner, cms_data, index = 0 }) => {
    const [{ isMobileType }] = useContext(HomePageContext);

    return (
        <PCarousel
            onChange={(i) => {
                sendHeroBannerImpressionGAEvent({
                    bannerItem: banner[i]
                })
            }}
            onClickItem={(i) => sendBannerClickGAEvent({
                bannerItem: banner[i],
            })}
            showThumbs={false}
            index={index}
            preventMovementUntilSwipeScrollTolerance
            interval={3000}
            autoPlay
            showStatus={false}
            stopOnHover
            emulateTouch
            infiniteLoop
        >
            {banner
                .slice(0, parseInt(cms_data.homepage_banner_slider_totalbanner)).sort((a, b) => a.seq - b.seq)
                .map((item, index) => (
                    <div className={styles.home_mobile_banner} key={item.id || index}>
                        <span>
                            <Link prefetch={false} href={item.link}>

                                {
                                    !isMobileType ? (
                                        (item.url_d.includes(".mp4") ?
                                            <video width="2800" autoPlay loop muted playsInline poster="/images/Placeholder_banner_desktop.jpg" preload="auto">
                                                <source src={item.url_d} type="video/mp4" />
                                                <source src="movie.ogg" type="video/ogg" />
                                                Your browser does not support the video tag.
                                            </video> :
                                            // <Image priority src={`${item.url_d}`} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" alt="kama ayurveda banner" width={1400} height={400} layout="responsive" />
                                            <img className={styles.homeBanner} loading={index == 0 ? 'eager' : 'lazy'}
                                                src={cloudflareLoader({
                                                    src: item.url_d,
                                                    width: 1400,
                                                    quality: 80,
                                                })}
                                                alt="kama ayurveda banner" />
                                        )) : (
                                        (item.url_m.includes(".mp4") ?
                                            <video width="991" data-wf-ignore="true" data-object-fit="cover" playsInline autoPlay muted loop poster="/images/Placeholder_banner_mobile.jpg" preload="auto">
                                                <source src={item.url_m} type="video/mp4" data-wf-ignore="true" />
                                            </video> :
                                            // <Image priority src={`${item.url_m}`} placeholder="blur" blurDataURL="/images/kama-placeholder.jpg" alt="kama ayurveda banner" width={440} height={600} />
                                            <img className={styles.homeBanner} loading={index == 0 ? 'eager' : 'lazy'}
                                                src={cloudflareLoader({
                                                    src: item.url_m,
                                                    width: 450,
                                                    quality: 80,
                                                })}
                                                alt="kama ayurveda banner" />

                                        ))
                                }
                            </Link>
                        </span>
                    </div>
                )
                )}
        </PCarousel>
    );
}

export default HomepageBanner